// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  locationURL: 'https://integration-qa.gofrugalretail.com/',
  ecomURL: 'https://integration-qa.gofrugalretail.com/ecommerce/',
  ondcURL: 'https://integration-qa.gofrugalretail.com/ondc/',
  accountURL: 'https://integration-qa.gofrugalretail.com/accounts/',
  paymentURL: 'https://integration-qa.gofrugalretail.com/payment/',
  smartReports: 'https://integration-qa.gofrugalretail.com/reports/#/reports?',
  srDashboard: 'https://integration-qa.gofrugalretail.com/reports/#/dashBoard?',
  storePurchase: 'http://labteststore.gofrugal.com/service_index.php?',
  link742: 'https://ordereasy-qa.gofrugalretail.com/',
  link538: 'https://godeliver-qa.gofrugalretail.com/',
  link744: 'https://gotracker-qa.gofrugalretail.com/',
  faURL: 'https://integration-qa.gofrugalretail.com/fa/',
  loyaltyURL: 'https://alertqa.gofrugal.com/Loyalty/',
  smartReportsNew: 'https://gftintegration.localzoho.com/smartreport/index.html#/reports?',
  accountNewURL: 'https://gftintegration.localzoho.com/accounts/'
};
